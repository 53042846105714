import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

export const apiTestSpreadsheet = async (spreadsheets : File[]) => {
    const formData = new FormData();
    spreadsheets.forEach((file) => formData.append('files',file));
    return await api.post('/scrape-xlsx/test-file', formData)
}
