import styled from "styled-components";

declare interface GetColorProps {
  isDragAccept : boolean;
  isDragReject : boolean;
  isDragActive : boolean;
}

const getColor = ( props : GetColorProps) : string => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

declare interface ContainerProps extends GetColorProps{

}

export const Container = styled.div<ContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

interface MessageColors {
  default: string;
  error : string;
  success : string;
}
const messageColors : MessageColors = {
  default: "#999",
  error: "#e57878",
  success: "#78e5d5",
};

declare interface UploadMessageProps {
  type?: keyof MessageColors;
}
export const UploadMessage = styled.p<UploadMessageProps>`
  display: flex;
  color: ${(props) => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;