interface t {
  [key: string]: {
    series: {};
    axes: {};
    dataConfig: {};
  };
}

const templates: t = {
  coluna: {
    axes: [
      { primary: true, type: "ordinal", position: "bottom" },
      { position: "left", type: "linear", stacked: false, show: false },
    ],
    series: {
      type: "bar",
    },
    dataConfig: {
      series: 8,
      datums: 3,
      dataType: "ordinal",
    },
  },

  linha: {
    series: {
      showPoints: false,
    },
    axes: [
      {
        primary: true,
        type: "time",
        position: "bottom",
      },
      { type: "linear", position: "left" },
    ],

    dataConfig: {
      series: 10,
    },
  },
};

export default templates;
