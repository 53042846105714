import React from "react";
import Dashboard from "pages/dashboard";
import NotFoundPage from "pages/404";
import TestSpreadsheetPage from "pages/testSpreadsheet"
import { Route, RouteProps } from "react-router-dom";
import RootPrivatePage from "pages/RootPrivatePage";

interface RoutesProps {
  id: number;
  url: string;
  path: string;
  exact: boolean;
  component: React.ComponentType<any>;
}

export const landingRoute: RoutesProps = {
  id: 1,
  url: "/",
  path: "/",
  exact: true,
  component: Dashboard,
};

export const DashboardRoute: RoutesProps = {
  id: 2,
  url: "/dashboard",
  path: "/dashboard",
  exact: true,
  component: Dashboard,
};

export const notFoundRoute: RoutesProps = {
  id: 3,
  url: "/404",
  path: "/404",
  exact: true,
  component: NotFoundPage,
};

export const TestSpreadsheetRoute: RoutesProps = {
  id: 4,
  url: "/test-spreadsheet",
  path: "/test-spreadsheet",
  exact: true,
  component: TestSpreadsheetPage,
}

export const privateRoutes: RoutesProps[] = [DashboardRoute, TestSpreadsheetRoute];
export const publicRoutes: RoutesProps[] = [notFoundRoute, landingRoute];

export const PublicRoute: React.FC<RouteProps> = (props) => {
  return <Route {...props} />;
};

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={() => (
        <RootPrivatePage>
          <Component />
        </RootPrivatePage>
      )}
    />
  );
};
