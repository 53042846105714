import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import {
  privateRoutes,
  PrivateRoute,
  publicRoutes,
  PublicRoute,
  notFoundRoute,
} from "routes";

function App() {
  return (
    <Router>
      <Switch>
        {publicRoutes.map((route) => (
          <PublicRoute
            key={route.id}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        {privateRoutes.map((route) => (
          <PrivateRoute
            key={route.id}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        <Redirect to={notFoundRoute.path} />
      </Switch>
    </Router>
  );
}

export default App;
