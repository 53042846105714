import React from "react";
import Chart from "./Chart";
import { Card, CardContent } from "./styles";

interface ChartCardProps {
  data: {} | null;
  fetching: boolean;
  error: boolean;
}

const ChartCard: React.FC<ChartCardProps> = ({ data, fetching, error }) => (
  <Card>
    <CardContent>
      {error ? "erro" : fetching ? "carregando" : <Chart data={data} />}
    </CardContent>
  </Card>
);

export default ChartCard;
