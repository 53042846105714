import React, { useCallback } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import {
  Container,
  UploadMessage,
} from "./styles";

declare interface StyledDropzoneProps {
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const StyledDropzone: React.FC<StyledDropzoneProps> = ({ setFiles }) => {
  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[]
  ) => void = useCallback(
    (acceptedFiles) => {
      setFiles((prev) => [...prev, ...acceptedFiles]);
    },
    [setFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept:
      ".xlsx , .xls , application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <UploadMessage>
        Arraste os arquivos ou CLIQUE AQUI para escolher
      </UploadMessage>
    </Container>
  );
};

export default StyledDropzone;
