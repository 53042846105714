import React from "react";

import { Chart } from "react-charts";
import useDemoConfig from "./useChartConfig";
import configTemplates from './ChartTemplates'
import ChartBox from "./ChartBox";
import "./styles.css";

const tipoGrafico = 'coluna'
export default function ChartComponent({data:datateste}) {

  const { data } = useDemoConfig({
    ...configTemplates[tipoGrafico].dataConfig,
    x_data: datateste?.x_data,
    y_data: datateste?.y_data,
  });

  const series = React.useMemo(
    () => configTemplates[tipoGrafico].series,
    []
  );

  const axes = React.useMemo(
    () => configTemplates[tipoGrafico].axes,
    []
  );

  return (
    <>
      <ChartBox>
        <Chart data={data} series={series} axes={axes} tooltip />
      </ChartBox>
    </>
  );
}
