import styled from "styled-components";
import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';

export const Card = styled(MuiCard)`
    display : flex;
    justify-content: center;
    max-width: 700px;
    `;

export const CardContent = styled(MuiCardContent)`
`;

