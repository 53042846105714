import React from "react";

declare interface ChartBoxProps extends React.FC{
  width : number,
  height : number,
  style : {},
  className: string, 
}


const ChartBox : React.FC <ChartBoxProps> = ({
  children,
  width = 500,
  height = 300,
  style = {},
  className,
}) => {
  return (
    <div>
      {
        <div
          style={{
            width: `${width}px`,
            height: `${height}px`,
            ...style
          }}
          className={className}
        >
          {children}
        </div>
      }
    </div>
  );
}

export default ChartBox;
