import React from "react";
import Header from "components/common/Header"

const RootPage: React.FC = ({ children }) => (
    <Header>
      {children}
    </Header>
);

export default RootPage;
