import React, { useState } from "react";
import Upload from "components/testSpreadsheet/Upload";
import ChartsList from "components/testSpreadsheet/ChartsList";

const TestSpreadsheetPage = () => {
  const [files, setFiles] = useState<File[]>([])
  return (
    <div>
      envie a planilha
      <Upload setFiles={setFiles}/>
      <ChartsList files={files}/>
    </div>
  );
};

export default TestSpreadsheetPage;
