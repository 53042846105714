import { apiTestSpreadsheet } from "utils/common/fetch_api";
import { FileInfo } from "./index";

export const addInsertedFiles = (files: File[], filesAdded: number) => {
  return (current: FileInfo[]) => {
    for (let i = files.length - filesAdded; i < files.length; i++) {
      current.push({
        file: files[i],
        chartData: {
          fetching: false,
          data: null,
          error: false,
        },
      });
    }
    return [...current];
  };
};

export const handleFetchChart = (
  fileInfo: FileInfo,
  index: number,
  setFilesInfo: React.Dispatch<React.SetStateAction<FileInfo[]>>
) => {
  setFilesInfo((current) => {
    current[index] = {
      ...current[index],
      chartData: {
        ...current[index].chartData,
        fetching: true,
      },
    };
    return [...current];
  });
  apiTestSpreadsheet([fileInfo.file]).then(
    (res) => {
      setFilesInfo((curr) => {
        curr[index] = {
          ...curr[index],
          chartData: {
            ...curr[index].chartData,
            fetching: false,
            data: res.data[0],
          },
        };
        return [...curr];
      });
    },
    () => {
      setFilesInfo((curr) => {
        curr[index] = {
          ...curr[index],
          chartData: {
            ...curr[index].chartData,
            fetching: false,
            error: true,
          },
        };
        return [...curr];
      });
    }
  );
};
