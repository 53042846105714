import React from "react";
// import ChartCard from "components/common/ChartCard"

const Dashboard = () => {
    return (
        // <ChartCard/>
        <div>dashboard</div>
    );
};

export default Dashboard;
