import React, { useEffect, useState } from "react";
import { ChartsContainer, ChartWrapper } from "./styles";
import {addInsertedFiles, handleFetchChart} from './utils'
import ChartCard from "components/common/ChartCard"

declare interface ChartListProps {
  files: File[];
}

export declare interface FileInfo {
  file: File;
  chartData: {
    fetching: boolean;
    data: {} | null;
    error: boolean;
  };
}


const ChartList: React.FC<ChartListProps> = ({ files }) => {
  const [filesInfo, setFilesInfo] = useState<FileInfo[]>([]);
  useEffect(() => {
    const filesAdded = files.length - filesInfo.length;
    if (filesAdded > 0) setFilesInfo(addInsertedFiles(files, filesAdded));
  }, [files, filesInfo.length]);

  useEffect(() => {
    filesInfo.forEach((fileInfo, index) => {
      if (
        fileInfo.chartData.data === null &&
        fileInfo.chartData.fetching === false
      ) {
        handleFetchChart(fileInfo, index, setFilesInfo);
      }
    });
  }, [filesInfo]);

  return (
    <ChartsContainer>
      lista dos graficos
      {filesInfo.map((fileInfo, index) => (
        <ChartWrapper key={index}>
          <br />
          <ChartCard {...fileInfo.chartData}/>
        </ChartWrapper>
      ))}
    </ChartsContainer>
  );
};


export default ChartList;
