import React from "react";

export default function useChartConfig({
  series,
  useR,
  x_data,
  y_data,
  show = [],
  count = 1,
  resizable = true,
  canRandomize = true,
  dataType = "linear",
  elementType = "line",
  primaryAxisType = "time",
  secondaryAxisType = "linear",
  primaryAxisPosition = "bottom",
  secondaryAxisPosition = "left",
  primaryAxisStack = false,
  secondaryAxisStack = true,
  primaryAxisShow = true,
  secondaryAxisShow = true,
  tooltipAnchor = "closest",
  tooltipAlign = "auto",
  grouping = "primary",
  snapCursor = true,
  datums = 10,
}) {
  const [state] = React.useState({
    count,
    resizable,
    canRandomize,
    dataType,
    elementType,
    primaryAxisType,
    secondaryAxisType,
    primaryAxisPosition,
    secondaryAxisPosition,
    primaryAxisStack,
    secondaryAxisStack,
    primaryAxisShow,
    secondaryAxisShow,
    tooltipAnchor,
    tooltipAlign,
    grouping,
    snapCursor,
    datums,
    data: makeDataFrom(dataType, x_data, y_data),
  });

  return {
    ...state,
  };
}

function makeDataFrom(dataType, x_data, y_data) {
  const res = [...new Array(x_data?.series.length || 0)].map((_, i) =>
    makeSeries(
      dataType,
      { label: x_data.title, data: x_data.series[i] },
      y_data.map((y) => ({ label: y.title, data: y.series[i] })),
      y_data.length,
    )
  );
  return res;
}

function makeSeries( dataType, x, y, y_length) {
  const start = 0;
  const length = y_length;
  return {
    label: x.data,
    data: [...new Array(length)].map((_, i) => {
      let x = start + i;
      if (dataType === "ordinal") {
        x = y[i].label;
      }
      const out_y = y[i].data;
      return {
        primary: x,
        secondary: out_y,
      };
    }),
  };
}
